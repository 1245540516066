<template>
  <div class="main primary_color">
    <!-- nav -->
    <my-nav
      :current="current"
      @onHandleShow="handleShow"
    ></my-nav>

    <!-- 关于我们 -->
    <son-banner :title="title" :enTitle="enTitle" :current="current"></son-banner>

    <!-- 公司介绍 -->
    <div id="aboutus1" class="column a_center w-per100 minh906">
      <div class="aboutus w-per93 h484 mt80"></div>
      <div class="w-per62_5 column a_center mt-484">
        <div class="mt20 ft36 bolder wow animate__animated animate__fadeInUp" data-wow-duration="0.6s" data-wow-iteration="1">
          公司介绍
        </div>
        <div class="w-per100 row j_sb">
          <div class="w-per50 mt96">
            <div class="show_color ft32 wow animate__animated animate__fadeInUp" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
              {{ company.title }}
            </div>
            <div class="richText ft24 mt50 wow animate__animated animate__fadeInUp" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
              <!-- 富文本 -->
            </div>
          </div>
          <div class="w-per41 h574 mt70 mb110">
            <img class="w-per100 h-per100 wow animate__animated animate__fadeInUp" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s" :src="company.thumb" >
          </div>
        </div>
      </div>
    </div>

    <!-- 核心业务 -->
    <div id="aboutus2" class="aboutus1 column a_center w-per100 minh750">
      <div class="w-per62_5 column a_center">
        <div class="mt120 ft36 bolder wow animate__animated animate__fadeInUp" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
          核心业务
        </div>
        <div class="w-per100 mt130 row">
          <div class="w-per16 h50 column flex_center wow animate__animated animate__zoomIn" 
            data-wow-duration="0.6s" data-wow-iteration="1" :data-wow-delay="index*0.1+'s'"
            :class="index==0?'':'bdl_white'"
            v-for="(item,index) in business" :key="index">
            <img class="wh60" :src="item.thumb" alt="">
            <div class="mt15 ft20">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 联系我们 -->
    <div id="aboutus3" class="aboutus2 column a_center w-per100 minh750">
      <div class="w-per62_5 column a_center">
        <div class="mt120 ft36 bolder wow animate__animated animate__fadeInUp" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
          联系我们
        </div>

        <div class="w-per100 row j_sb a_center">
          <div class="w-per50 wow animate__animated animate__bounceIn" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
            <div class="row">
              <div class="ft46 mr8 bolder" v-for=" (item,index) in '让您的产品曝光网络，' " :key="index">
                {{ item }}
              </div>
            </div>
            <div class="row mt20">
              <div class="ft46 mr8 bolder" :class="'量身打造'.includes(item)?'show_color':''" v-for=" (item,index) in '为您量身打造传播方案' " :key="index">
                {{ item }}
              </div>
            </div>
          </div>

          <div class="w-per41 mt180 mb178 row j_sb">
            <div class="wh220 br30 column flex_center bg_w wow animate__animated animate__bounceIn"
              data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s"
              v-for="(item,index) in contact" :key="index">
              <img class="wh80" :src="item.thumb" alt="">
              <div class="ft24 bolder mt24 text_nowrap">
                {{ index==0?'海量媒体选择':'专业团队服务' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- footer -->
    <my-footer :current="current" @onScrollTo="scroll"></my-footer>
  </div>
</template>

<script>
import { getSeoList, getAbout, otherVal } from "@/api/app"
export default {
  metaInfo() {
    return {
      title: '关于我们',
      meta: [
        {
          name: "keywords",
          content: this.PagekeyWords,
        },
        {
          name: "description",
          content: this.PageDescription,
        },
        {
          property: "og:type",
          content: 'website',
        },
        {
          property: "og:title",
          content: this.PageTitle,
        },
        {
          property: "og:description",
          content: this.PageDescription,
        },
        {
          property: "og:site_name",
          content: this.PageTitle,
        },
        {
          property: "og:url",
          content: 'https://www.sclhbc.com/#/aboutus',
        },
        {
          property: "og:image",
          content: 'https://www.sclhbc.com/favicon.ico',
        },
      ],
    };
  },
  data() {
    return {
      PageTitle: "",
      PagekeyWords: "",
      PageDescription: "",
      contact:[],//联系我们
      business:[],//核心业务
      company:{},//公司介绍
      otherdata:{},
      title:'关于我们',
      enTitle:'ABOUT US',
      current: 3,
    }
  },
  mounted() {
    this.fetchSeoList()

    this.fetchAbout()
    
  },
  
  methods: {
    async fetchSeoList() {
      let {data} = await getSeoList()
      this.tit = data.data.val
      // console.log('tt',this.tit);
      this.PageTitle = this.tit.zd_name?this.tit.zd_name:'联合百川'
      this.PagekeyWords = this.tit.wz_keyword?this.tit.wz_keyword:'联合百川'
      this.PageDescription = this.tit.zd_desc?this.tit.zd_desc:'四川联合百川科技有限公司'
    },
    handleShow(index,id) {
      // console.log(id,index)
      this.current = index
      if (index == 0) {
        this.$router.push({
          path: "/",
        })
      }
      if (index == 1) {
        this.$router.push({
          path: "/product",
        })
      }
      if (index == 2) {
        this.$router.push({
          path: "/advantage",
        })
      }
      this.handelUpdate()
      if(id){
        setTimeout(()=>{
          var el = document.getElementById(id)
          this.$nextTick(function () {
            window.scrollTo({
              behavior: "smooth",
              top: el.offsetTop + -88,
            })
          })
        },100)
      }else{
        return
      }
    },
    handelUpdate() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    },
    scroll({id, offset, num}) {
      // console.log("111")
      if (num == -1) {
        offset = offset || -10
        var el = document.getElementById(id)
        this.$nextTick(function () {
          window.scrollTo({
            behavior: "smooth",
            top: el.offsetTop + offset,
          })
        })
      } else {
        this.handleShow(num,id)
      }
    },
    async fetchAbout(){
      let {data} = await getAbout()
      // console.log('getAbout',data.data);
      this.contact = data.data.our_serve.contact_us
      this.business = data.data.our_serve.core_business
      this.company = data.data.our_serve.about;
      this.fetchOtherVal()
    },
    async fetchOtherVal() {
      let {data} = await otherVal()
      this.otherdata = data.data;

      // console.log('other',this.otherdata);
      
      let aboutus = document.querySelector('.aboutus');
      aboutus.style.backgroundImage = `url(${this.otherdata.img_seven})`
      aboutus.style.backgroundSize = "100% 100%"

      let aboutus1 = document.querySelector('.aboutus1');
      aboutus1.style.backgroundImage = `url(${this.otherdata.img_eight})`
      aboutus1.style.backgroundSize = "100% 100%"

      let aboutus2 = document.querySelector('.aboutus2');
      aboutus2.style.backgroundImage = `url(${this.otherdata.img_nine})`
      aboutus2.style.backgroundSize = "100% 100%"

      let richT = document.querySelector('.richText')
      richT.innerHTML = this.company.content;
    },
  },
}
</script>

<style scoped lang="scss">
.main{
  width: 100%;
  min-height: 100%;
  // padding-top: 5.2083vw;
  margin: 0px;
  background-color: #fff;
  position: relative;
  overflow-x: hidden;

}

</style>
