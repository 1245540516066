<template>
  <div class="main primary_color">
    <!-- nav -->
    <my-nav
      :current="current"
      @onHandleShow="handleShow"
    ></my-nav>

    <!-- 产品展示 -->
    <son-banner :title="title" :enTitle="enTitle" :current="current"></son-banner>

    <!-- 痛点 -->
    <div id="product1" class="productBox1 column a_center w-per100 minh888">
      <div class="w1200 column a_center">
        <div class="ft36 bolder mt100 wow animate__animated animate__zoomIn" data-wow-duration="0.6s" data-wow-iteration="1">
          您是否觉得您的产品默默无闻？
        </div>

        <div class="w-per100 h536 mt100 mb100 row wrap j_sb ac_sb relative wow animate__animated animate__zoomIn" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
          <div class="w320 h180 bg_FCEEED br10 px40 py20" :class="index%2==1?'ml200':'mr200'" v-for="(item,index) in product1" :key="index">
            <div class="row a_center">
              <img class="wh40" :src="item.thumb" >
              <div class="ft22 ml10">
                {{ item.title }}
              </div>
            </div>
            <div class="ft18 mt20 text_wrap lh36">{{ item.content }}</div>
          </div>
          <div class="w560 h400 absolute top-per50 left-per50 tr-per50 relative">
            <img class="w560 h400" src="https://www.sclhbc.com/common/tongdian.png" >
            <div class="wh304 absolute top-per50 left-per50 tr-per50 relative">
              <img class="wh304" :src="otherdata.img_sixteen" >
              <div class="absolute top-per50 left-per50 tr-per50 ft48 bolder white_color text_nowrap">
                痛点
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 联动力 -->
    <div id="product2" class="productBox2 column a_center w-per100 minh1200">
      <div class="w-per62_5 column a_center">
        <div class="ft36 bolder mt90 wow animate__animated animate__zoomIn" data-wow-duration="0.6s" data-wow-iteration="1">
          请您看看我们的联动力推广平台
        </div>

        <div class="mt60 mb80 column a_center">
          <!-- 列表 -->
          <div class="w-per100 h420 br20 bg_w row wrap wow animate__animated animate__slideInUp" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
            <div class="w-per25 h-per100" v-for="(item,index) in product2_1" :key="index">
              <!-- 单 上 -->
              <div class="w-per100 h-per50 relative" v-if="index%2==0">
                <img class="w-per100 h-per100" :class="index==0?'brtl':''" :src="item.background" >
                <div class="w-per100 h-per100 column flex_center absolute top0 left0 zIndex10">
                  <div class="wh88 bg_fff-6 br-per100 column flex_center">
                    <img class="wh46" :src="item.thumb" >
                  </div>
                </div>
              </div>
              <!-- 双 上 -->
              <div class="w-per100 h-per50 column flex_center" v-else>
                <div class="show_color ft20">
                  {{ item.title }}
                </div>
                <div class="w-per85 ft16 text_center mt15 lh36">
                  {{ item.englishtitle }}
                </div>
              </div>
              
              <div class="w-per100 h-per50 column flex_center" v-if="index%2==0">
                <div class="show_color ft20">
                  {{ item.title }}
                </div>
                <div class="w-per85 ft16 text_center mt15 lh36">
                  {{ item.englishtitle }}
                </div>
              </div>
              <div class="w-per100 h-per50 relative" v-else>
                <img class="w-per100 h-per100" :class="index==3?'brbr':''" :src="item.background" >
                <div class="w-per100 h-per100 column flex_center absolute top0 left0 zIndex10">
                  <div class="wh88 bg_fff-6 br-per100 column flex_center">
                    <img class="wh46" :src="item.thumb" >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- banner -->
          <div class="w-per100 h440 br20 mt55 column flex_center relative">
            <div class="w-per100 h-per100">
              <img class="w-per103 h-per109 ml-20 mt-20" src="https://www.sclhbc.com/common/liandongli_banner_bg.png" >
            </div>
            <div class="w-per100 absolute top0 left0 zIndex10 row j_sb a_center">
              <div class="w-per40 ml70">
                <div class="white_color ft24 lh46">
                  {{ product_obj.title }}
                </div>
                <div class="w35 h10 mt95">
                  <img class="w-per100 h-per100" src="https://www.sclhbc.com/common/icon_baijiantou.png" alt="">
                </div>
              </div>
              <div class="w-per42 h300 br20 my70 mr80 wow animate__animated animate__slideInUp" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
                <img class="w-per100 h-per100 br20" :src="product_obj.thumb" alt="">
              </div>
            </div>
            <div class="absolute top-per50 left-80 trY-per50 zIndex10 pointer" @click="prevBtn">
              <img class="wh50" src="https://www.sclhbc.com/common/icon_zuojiantou.png" >
            </div>
            <div class="absolute top-per50 right-80 trY-per50 zIndex10 pointer" @click="nextBtn">
              <img class="wh50" src="https://www.sclhbc.com/common/icon_youjiantou.png" >
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- 多场景 -->
    <div id="product3" class="column a_center w-per100">
      <div class="ft36 bolder mt20 wow animate__animated animate__zoomIn" data-wow-duration="0.6s" data-wow-iteration="1">
        联合百川拥有全系列多场景用户流量
      </div>
      <!-- 线上场景 -->
      <div class="productBox3 mt50 w-per100 column a_center">
        <div class="w-per62_5 column a_center">

          <div class="ft28 row flex_center mt60 w-per100 wow animate__animated animate__zoomIn" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
            <div class="icon-l-w"></div>
            <div class="mx12 bolder white_color">
              线上场景
            </div>
            <div class="icon-r-w"></div>
          </div>

          <div class="mt50 ft24 white_color">
            专属线上新媒体矩阵，同步推广引流，覆盖用户的各种<span class="bolder c_390B40"> 生活场景</span>
          </div>

          <div class="w-per100 h390 mt130 mb194 relative">
            <el-carousel ref="cardshow" :interval="2400" type="card" arrow="never" indicator-position="none" height="20.3125vw">
              <el-carousel-item class="w650" v-for="(item,index) in online" :key="index">
                <h3 class="w650 h390 o-h"><img class="w680 h410 mt-10 ml-10" :src="item.thumb" alt=""></h3>
              </el-carousel-item>
            </el-carousel>
            <div class="absolute top-per50 left-80 trY-per50 zIndex10 pointer" @click="prevCard">
              <img class="wh50" src="https://www.sclhbc.com/common/icon_zuojiantou.png" >
            </div>
            <div class="absolute top-per50 right-80 trY-per50 zIndex10 pointer" @click="nextCard">
              <img class="wh50" src="https://www.sclhbc.com/common/icon_youjiantou.png" >
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- 线下场景 -->
    <div class="productBox4 column a_center w-per100 minh1544">
      <div class="w-per62_5 column a_center">

        <div class="ft28 row flex_center mt100 w-per100 wow animate__animated animate__zoomIn" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
          <div class="icon-l-b"></div>
          <div class="mx12 bolder">
            线下场景
          </div>
          <div class="icon-r-b"></div>
        </div>
        <div class="mt50 ft24 show_color bolder wow animate__animated animate__pulse" data-wow-duration="1s" data-wow-iteration="1" data-wow-delay="0.2s">
          拥有丰富汽车资源
        </div>
        <div class="mt30 ft20">
          可帮您打造全方位的汽车展示平台，增强品牌影响力
        </div>

        <div class="mt60 w-per100 row j_sb wrap">
          <div class="mt20 w-per49 column a_center wow animate__animated animate__flipInX"
            data-wow-duration="0.6s" data-wow-iteration="1" :data-wow-delay="(index+1)*0.2+'s'"
            :class="index%2==1?'ml20':''"
            v-for="(item,index) in outline" :key="index">
            <div class="w-per100 h350">
              <img class="w-per100 h-per100" :src="item.thumb" alt="">
            </div>
            <div class="w-per100 bg_w py10 px20">
              <div class="ft18 bolder lh46">
                {{ item.title }}
              </div>
              <div class="ft16 info_color mt10 lh36">
                {{ item.content }}
              </div>
            </div>
          </div>
        </div>

        <div class="mt105 ft24 show_color bolder wow animate__animated animate__pulse" data-wow-duration="1s" data-wow-iteration="1" data-wow-delay="0.2s">
          拥有丰富的场地资源
        </div>
        <div class="mt30 mb60 ft20 text_nowrap">
          与各种场所（如商场、社区、学校、公共交通站点等）合作，租用或借用其空间进行宣传活动，如摆设展示台、悬挂横幅、张贴海报等
        </div>

      </div>
    </div>
    <!-- 场地资源 -->
    <div class="areaBox w-per100 mb80 ft24 white_color">
      <div class="box">
      </div>
    </div>
    <!-- 伙伴资源 -->
    <div class="productBox5 column a_center w-per100 minh300 white_color">
      <div class="mt126 ft24 bolder wow animate__animated animate__pulse" data-wow-duration="1s" data-wow-iteration="1" data-wow-delay="0.2s">
        拥有丰富合作伙伴资源
      </div>
      <div class="mt30 ft20">
        与其他企业或机构合作，共享资源，互利共赢
      </div>
    </div>
    <div class="productBox6 column a_center w-per100 minh980">
      <div class="w-per62_5 column a_center mt60">
        <div class="w-per100 h400 mt20 row wow animate__animated"
          :class="index%2==0?'animate__slideInLeft':'animate__slideInRight'"
          data-wow-duration="1s" data-wow-iteration="1" :data-wow-delay="(index+1)*0.2+'s'"
          v-for="(item,index) in partner" :key="index">
          <!-- 单左 -->
          <div class="w-per75 h-per100" v-if="index%2==0">
            <img class="w-per100 h-per100" :src="item.images" alt="">
          </div>
          <!-- 双左 -->
          <div class="w-per25 h-per100 bg_w px40" v-else>
            <div class="mt90 ft20 lh46">
              {{ item.title }}
            </div>
            <div class="mt50 row j_sb">
              <img class="wh80" src="https://www.sclhbc.com/common/icon_gongxiangziyuan.png" alt="">
              <img class="wh80" src="https://www.sclhbc.com/common/icon_hulihuying.png" alt="">
            </div>
          </div>
          <!-- 单右 -->
          <div class="w-per25 h-per100 bg_w px40" v-if="index%2==0">
            <div class="mt90 ft20 lh46">
              {{ item.title }}
            </div>
            <div class="mt50 row j_sb">
              <img class="wh80" src="https://www.sclhbc.com/common/icon_gongxiangziyuan.png" alt="">
              <img class="wh80" src="https://www.sclhbc.com/common/icon_hulihuying.png" alt="">
            </div>
          </div>
          <div class="w-per75 h-per100" v-else>
            <img class="w-per100 h-per100" :src="item.images" alt="">
          </div>
        </div>
      </div>
    </div>

    <!-- footer -->
    <my-footer :current="current" @onScrollTo="scroll"></my-footer>
  </div>
</template>

<script>
import { getSeoList, productView, otherVal } from "@/api/app"
export default {
  metaInfo() {
    return {
      title: '产品展示',
      meta: [
        {
          name: "keywords",
          content: this.PagekeyWords,
        },
        {
          name: "description",
          content: this.PageDescription,
        },
        {
          property: "og:type",
          content: 'website',
        },
        {
          property: "og:title",
          content: this.PageTitle,
        },
        {
          property: "og:description",
          content: this.PageDescription,
        },
        {
          property: "og:site_name",
          content: this.PageTitle,
        },
        {
          property: "og:url",
          content: 'https://www.sclhbc.com/#/product',
        },
        {
          property: "og:image",
          content: 'https://www.sclhbc.com/favicon.ico',
        },
      ],
    };
  },
  data() {
    return {
      PageTitle: "",
      PagekeyWords: "",
      PageDescription: "",
      partner: [],//伙伴资源
      areaList: [],//场地资源
      outline: [],//线下场景
      online: [],//线上场景
      product_obj: {},
      product_num: 0,//翻页
      product2_2: [],//联动力banner
      product2_1: [],//联动力列表
      product1: [],//痛点
      otherdata: {},
      title: '产品展示',
      enTitle: 'PRODUCT DISPLAY',
      current: 1,
    }
  },
  mounted() {
    this.fetchSeoList()

    this.fetchProduct();
    this.fetchOtherVal();
  },
  methods: {
    async fetchSeoList() {
      let {data} = await getSeoList()
      this.tit = data.data.val
      // console.log('tt',this.tit);
      this.PageTitle = this.tit.zd_name?this.tit.zd_name:'联合百川'
      this.PagekeyWords = this.tit.wz_keyword?this.tit.wz_keyword:'联合百川'
      this.PageDescription = this.tit.zd_desc?this.tit.zd_desc:'四川联合百川科技有限公司'
    },
    // 线上场景-翻页
    prevCard(){
      this.$refs.cardshow.prev();
    },
    nextCard(){
      this.$refs.cardshow.next();
    },
    // 联动力banner-翻页
    prevBtn(){
      if(this.product_num<=0){
        this.product_num = this.product2_2.length - 1;
      }else{
        this.product_num--;
      }
      this.product_obj = this.product2_2[this.product_num];
      return ;
    },
    nextBtn(){
      if(this.product_num>=this.product2_2.length - 1){
        this.product_num = 0;
      }else{
        this.product_num++;
      }
      this.product_obj = this.product2_2[this.product_num];
      return ;
    },
    handleShow(index,id) {
      // console.log(id,index)
      this.current = index
      if (index == 0) {
        this.$router.push({
          path: "/",
        })
      }
      if (index == 2) {
        this.$router.push({
          path: "/advantage",
        })
      }
      if (index == 3) {
        this.$router.push({
          path: "/aboutus",
        })
      }
      this.handelUpdate()
      if(id){
        setTimeout(()=>{
          var el = document.getElementById(id)
          this.$nextTick(function () {
            window.scrollTo({
              behavior: "smooth",
              top: el.offsetTop + -88,
            })
          })
        },100)
      }else{
        return
      }
    },
    handelUpdate() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    },
    scroll({id, offset, num}) {
      // console.log("111")
      if (num == -1) {
        offset = offset || -10
        var el = document.getElementById(id)
        this.$nextTick(function () {
          window.scrollTo({
            behavior: "smooth",
            top: el.offsetTop + offset,
          })
        })
      } else {
        this.handleShow(num,id)
      }
    },
    // 场地资源
    showAreaList(){
      // 获取盒子
      let box = document.getElementsByClassName("box")[0]
      function assData(data){     
        box.innerHTML = ""
        for(var i = 0; i<data.length;i++ ){
          box.innerHTML += `
            <div class="box1">
              <img class="imgc" src="${data[i]["bg_img"]}" alt="">
              <div class="inBox">
                <img class="wh75" src="${data[i]["thumb"]}" alt="">
                <span class="textBox">${data[i]["title"]}</span>
              </div>
            </div>`
        }
      }
      // 写子节点
      assData(this.areaList)
      // 获取这类节点
      let boxList = document.getElementsByClassName("box1");
      // 给中间这个节点添加类名
      boxList[3].children[1].classList.add('inBoxhover')
      for (let i = 0; i < this.areaList.length; i++) {
        // 监听鼠标移入
        boxList[i].onmouseover = function () {
          if (this.style.width == '11.1979vw') {//移到另一个
            // 清除
            for (let j = 0; j < boxList.length; j++) {
              boxList[j].style.width = '11.1979vw'
              boxList[j].children[1].classList.remove('inBoxhover')
            }
            // 加宽
            this.style.width = '29.6875vw'
            this.children[1].classList.add('inBoxhover')
          }else {
            // 移出到外面了
            if(this.style.width == '29.6875vw'){
              // 保持宽
              this.style.width = '29.6875vw'
              this.children[1].classList.add('inBoxhover')
            }else{
              this.style.width = '11.1979vw'
              this.children[1].classList.remove('inBoxhover')
            }
          }
        }
      }
    },
    // 其他
    async fetchOtherVal() {
      let {data} = await otherVal()
      this.otherdata = data.data;

      // console.log('other',this.otherdata);

      let productBox1 = document.querySelector('.productBox1');
      productBox1.style.backgroundImage = `url(${this.otherdata.img_fifteen})`
      productBox1.style.backgroundSize = "100% 100%"

      let productBox2 = document.querySelector('.productBox2');
      productBox2.style.backgroundImage = `url(${this.otherdata.img_fourteen})`
      productBox2.style.backgroundSize = "100% 100%"

      let productBox3 = document.querySelector('.productBox3');
      productBox3.style.backgroundImage = `url(${this.otherdata.img_seventeen})`
      productBox3.style.backgroundSize = "100% 100%"

      let productBox4 = document.querySelector('.productBox4');
      productBox4.style.backgroundImage = `url(${this.otherdata.img_eighteen})`
      productBox4.style.backgroundSize = "100% 100%"

      let productBox5 = document.querySelector('.productBox5');
      productBox5.style.backgroundImage = `url(${this.otherdata.img_twelve})`
      productBox5.style.backgroundSize = "100% 100%"

      let productBox6 = document.querySelector('.productBox6');
      productBox6.style.backgroundImage = `url(${this.otherdata.img_thirteen})`
      productBox6.style.backgroundSize = "100% 100%"
    },
    async fetchProduct(){
      let {data} = await productView();
      // console.log('fetchProduct',data.data);
      this.product1 = data.data.list;
      this.product2_1 = data.data.list_two;
      this.product2_2 = data.data.list_three;
      this.product_obj = this.product2_2[this.product_num]?this.product2_2[this.product_num]:{};
      this.online = data.data.list_four;
      this.outline = data.data.list_five;
      this.areaList = data.data.list_six;
      this.partner = data.data.list_seven;
      this.showAreaList();
    },
  },
}
</script>

<style scoped lang="scss">
.main{
  width: 100%;
  min-height: 100%;
  // padding-top: 5.2083vw;
  margin: 0px;
  background-color: #fff;
  position: relative;
  overflow-x: hidden;

  .productBox1{

  }
  .productBox2{
    
  }
  .productBox3{

    ::v-deep .el-carousel__mask{
      width: 33.8542vw;
    }
    .el-carousel__item h3 {
      line-height: 20.3125vw;
      margin: 0;
    }
  }
  .productBox4{
    
  }
  .areaBox{
    .box{
      width: 100%;
      height: 30.46875vw;
      display: flex;
      justify-content: space-between;
      ::v-deep .box1{
        width: 11.1979vw;
        height: 30.46875vw;
        border-radius: 1.0417vw;
        overflow: hidden;
        position: relative;
        transition: all 0.2s linear;
        .imgc{
          width: 29.6875vw;
          height: 30.46875vw;
        }
        .inBox{
          position: absolute;
          bottom: 2.6042vw;
          left: 50%;
          transform: translateX(-50%);
          z-index: 100;
          display: flex;
          flex-direction: column;
          align-items: center;
          .wh75{
            width: 3.90625vw;
            height: 3.90625vw;
          }
          .textBox{
            text-wrap: nowrap;
            margin-top: 0.5208vw;
            color: #fff;
          }
        }
        .inBoxhover{
          position: absolute;
          bottom: 2.6042vw;
          z-index: 100;
          left: 3.6458vw;
          transform: translateX(0);
        }
      }
      ::v-deep .box1:nth-of-type(4){
        width: 29.6875vw;
      }
    }
  }
}
</style>
