<template>
  <div class="w-per100 h600 column flex_center white_color" :style="{backgroundImage: 'url('+ bg +')',backgroundSize: '100% 100%'}">
    <div class="ft64 bolder mt50">{{ title }}</div>
    <div class="ft24 mt20">{{ enTitle }}</div>
    <div class="mt30 line-w"></div>
  </div>
</template>
<script>
import { otherVal } from '@/api/app'
export default {
  data() {
    return {
      bg:''
    }
  },
  props:{
    title:String,
    enTitle:String,
    current: Number,
  },
  mounted() {
    this.fetchOtherVal()
  },
  methods: {
    async fetchOtherVal() {
      let {data} = await otherVal()
      if(this.current == 1){
        this.bg = data.data.img_eleven
      }
      if(this.current == 2){
        this.bg = data.data.img_nineteen
      }
      if(this.current == 3){
        this.bg = data.data.img_six
      }
    },
  },
}
</script>
<style lang="scss">

</style>
