import Vue from 'vue'
import VueRouter from 'vue-router'

import NoFound from "../views/NoFound"
import Index from "../views/Index"
import Product from "../views/Product"
import Advantage from "../views/Advantage"
import AboutUs from "../views/AboutUs"

Vue.use(VueRouter); //使用插件

export const routes = [
  { //一级路由
    path: '/', // 根路由 
    name: 'index',
    component: Index,
    meta:{//初始化的meta
      isShow:true,
      title:'',
      content:{
        title:'',
        keywords:'',
        description:'',
      }
    }
    // redirect: "/index", //重定向
  },
  {
    path: '/product',
    name: 'product',
    component: Product,
  },
  {
    path: '/advantage',
    name: 'advantage',
    component: Advantage,
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: AboutUs,
  },
  {
    path: '*',
    component: NoFound
  }
]

const router = new VueRouter({
  routes,
  mode: 'hash' //路由匹配模式  hash 
})

/* 
  权限控制：把不需要登录的页面 称为 白名单 

*/

export default router
