<template>
  <div class="main primary_color">
    <!-- nav -->
    <my-nav :current="current" @onHandleShow="handleShow"></my-nav>

    <!-- banner -->
    <div class="bannerBox h800 relative">
      <el-carousel ref="bannershow" :height="bannerHeight" arrow="never" :interval="1800">
        <el-carousel-item v-for="(item) in banners" :key="item.id">
          <div class="w-vw100 h800 column flex_center relative">
            <div class="w-per100 h800">
              <img class="w-per100 h800" :src="item.thumb" alt="" />
            </div>
            <div class="w-per100 h800 column flex_center white_color absolute top0 left0 zIndex10">
              <div class="ft64 bolder mt50">
                {{ item.title }}
              </div>
              <div class="ft32 mt20">
                {{ item.englishtitle }}
              </div>
              <div class="mt30 line-w"></div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="prev absolute top-per50 left80 zIndex10" @click="prevBanner">
        <img class="wh50" src="https://www.sclhbc.com/common/iocn_select_zuojiantou.png" >
      </div>
      <div class="next absolute top-per50 right80 zIndex10" @click="nextBanner">
        <img class="wh50" src="https://www.sclhbc.com/common/icon_select_youjiantou.png" >
      </div>
    </div>

    <!-- 产品展示 -->
    <div id="product" class="productBox column a_center w-per100 minh945">
      <div class="w-per62_5">

        <div class="column flex_center mt100 w-per100 wow animate__animated animate__fadeInUp"
          data-wow-duration="0.8s"
          data-wow-delay="0.2s"
          data-wow-iteration="1">
          <div class="show_color ft36 bolder">
            产品展示
          </div>
          <div class="show_color ft32 mt8">
            PRODUCT DISPLAY
          </div>
          <div class="line mt30"></div>
        </div>

        <div class="ft28 row flex_center mt60 w-per100 wow animate__animated animate__fadeInUp" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
          <div class="icon-l-b"></div>
          <div class="mx12 bolder">
            联动力推广平台
          </div>
          <div class="icon-r-b"></div>
        </div>

        <div class="w-per100 mt60 row j_sb white_color">
          <div class="w-per50 h350 relative wow animate__animated animate__zoomIn" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s"
            v-for="(item,index) in synaptic" :key="index">
            <div class="w-per100 h-per100">
              <img class="w-per100 h-per100" :src="item.images" alt="">
            </div>
            <div class="w-per100 h-per100 column flex_center absolute top0 left0 zIndex10">
              <div class="wh80">
                <img class="w-per100 h-per100" :src="item.thumb" alt="">
              </div>
              <div class="ft20 mt20">
                {{ item.title }}
              </div>
              <div class="ft18 lh24 mt26 w-per70 lh36">
                {{ item.description }}
              </div>
            </div>
          </div>
        </div>

        <div class="ft28 row flex_center mt95 mb60 w-per100 wow animate__animated animate__fadeInUp" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
          <div class="icon-l-b"></div>
          <div class="mx12 bolder">
            全系列多场景用户流量
          </div>
          <div class="icon-r-b"></div>
        </div>

      </div>
    </div>

    <!-- 线上场景 线下场景 -->
    <div class="onlineBox column a_center w-per100 minh1060">
      <div class="w-per62_5 column a_center">
        <!-- 子导航 -->
        <div class="row flex_center">
          <div class="mt70 row flex_center pointer" :class="index==0?'':'ml30'" v-for="(item,index) in 2" :key="index" @click="changeline(index)">
            <div class="ft24 row flex_center mt60 w-per100 wow animate__animated animate__zoomIn" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
              <div class="icon-l-b" :class="line_num==index?'op10':'op0'"></div>
              <div class="mx12 bolder" :class="line_num==index?'show_color':''">
                {{ index==0?'线上场景':'线下场景' }}
              </div>
              <div class="icon-r-b" :class="line_num==index?'op10':'op0'"></div>
            </div>
          </div>
        </div>
        <!-- 内容 -->
        <div class="w-per100 column a_center">
          <div class="ft24 mt50">
            {{ line_num==0?online.description:outline.description }}
          </div>
          <div class="w-per100 mt50 mb100">
            <div class="w-per100 h700 wow animate__animated animate__zoomIn" v-if="line_num==0" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
              <img class="w-per100 h-per100" :src="online.thumb?online.thumb[0]:''" alt="">
            </div>
            <div class="w-per100 row wrap j_sb" v-else>
              <div class="w-per49 h350 mt20 wow animate__animated animate__zoomIn" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s"
                v-for="(item,index) in outline.thumb" :key="index">
                <img class="w-per100 h-per100" :src="item" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 平台优势 -->
    <div id="advantage" class="advantageBox column a_center w-per100 minh1200">
      <div class="w-per62_5 column a_center">

        <div class="column flex_center mt100 w-per100 wow animate__animated animate__fadeInUp"
          data-wow-duration="0.8s"
          data-wow-delay="0.2s"
          data-wow-iteration="1">
          <div class="white_color ft36 bolder">
            平台优势
          </div>
          <div class="white_color ft32 mt8">
            PLATFORM ADVANTAGE
          </div>
          <div class="line mt30" style="background: #ffffff;"></div>
        </div>

        <div class="w-per100 row wrap j_sb mt80 mb178">
          <div class="w550 mt70 row wow animate__animated animate__flipInX"
            data-wow-duration="0.6s" data-wow-iteration="1" :data-wow-delay="index<2?'0.2s':'0.6s'"
            v-for="(item,index) in advantage" :key="index">
            <div class="w-per100 row pb20" :class="index%2==0?'bd_show':'bd_show2'">
              <div class="w-per100 px35 pt35 pb24 ml-20 mt-20 bg_w br16 column j_sb">
                <div class="row a_center nowrap">
                  <div class="wh50 row a_center nowrap">
                    <img class="wh50" :src="item.thumb" alt="">
                  </div>
                  <div class="ft24 ml30">
                    {{ item.title }}
                  </div>
                </div>
                <div class="w-per100 ft20 info_color mt30 flex1 lh42">
                  {{ item.content }}
                </div>
                <div class="row j_end">
                  <img class="w28 h10" src="https://www.sclhbc.com/common/icon_jiantou.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- 关于我们 -->
    <div id="aboutus" class="aboutusBox column a_center w-per100 minh830">
      <div class="w-per62_5">

        <div class="column flex_center mt80 w-per100 wow animate__animated animate__fadeInUp"
          data-wow-duration="0.8s"
          data-wow-delay="0.2s"
          data-wow-iteration="1">
          <div class="show_color ft36 bolder">
            关于我们
          </div>
          <div class="show_color ft32 mt8">
            ABOUT US
          </div>
          <div class="line mt30"></div>
        </div>

        <div class="aboutText mt50 ft24 lh46 wow animate__animated animate__zoomIn" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
          {{ '&emsp;&emsp;'+about }}
        </div>

        <!-- 核心业务 -->
        <div class="ft28 row flex_center mt50 w-per100 wow animate__animated animate__fadeInUp" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
          <div class="icon-l-b"></div>
          <div class="mx12 bolder">
            核心业务
          </div>
          <div class="icon-r-b"></div>
        </div>

        <div class="w-per100 mt70 mb100 row j_sb a_center">
          <div class="column a_center wow animate__animated animate__zoomIn" 
            data-wow-duration="0.6s" data-wow-iteration="1" :data-wow-delay="index*0.1+'s'"
            v-for="(item,index) in business" :key="index">
            <img class="wh60" :src="item.thumb" alt="">
            <div class="mt15 ft20">
              {{ item.title }}
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- 咨询 -->
    <div class="consultBox column a_center w-per100 minh350">
      <div class="w-per62_5 row j_sb">
        <div class="mt60 ft28 bolder white_color">
          <div class="">
            获得更多推广相关资讯
          </div>
          <div class="mt10">
            让咨询顾问为您免费服务
          </div>
        </div>
        <div class="w-per51 mt60">
          <el-form :inline="true" :model="ruleForm" :rules="rules" class="">
            <div class="row j_sb">
              <el-form-item class="w-per49">
                <el-input v-model="ruleForm.name" placeholder="姓名"></el-input>
              </el-form-item>
              <el-form-item class="w-per49">
                <el-input v-model="ruleForm.company" placeholder="公司名称"></el-input>
              </el-form-item>
            </div>
            <div class="row j_sb mt20">
              <el-form-item class="w-per49">
                <el-input v-model="ruleForm.phone" placeholder="电话"></el-input>
              </el-form-item>
              <el-form-item class="w-per49">
                <el-input v-model="ruleForm.city" placeholder="所在城市"></el-input>
              </el-form-item>
            </div>
          </el-form>
          <div class="w-per100 h60 bg_w mt30 mb60 column flex_center pointer" @click="to_sub">
            <div class="show_color ft20">
              立即提交
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- footer -->
    <my-footer :current="current" @onScrollTo="scroll"></my-footer>

  </div>
</template>

<script>
import { getSeoList, otherVal, getIndex, consultSub } from "@/api/app"

export default {
  metaInfo() {
    return {
      title: '联合百川',
      meta: [
        {
          name: "keywords",
          content: this.PagekeyWords,
        },
        {
          name: "description",
          content: this.PageDescription,
        },
        {
          property: "og:type",
          content: 'website',
        },
        {
          property: "og:title",
          content: this.PageTitle,
        },
        {
          property: "og:description",
          content: this.PageDescription,
        },
        {
          property: "og:site_name",
          content: this.PageTitle,
        },
        {
          property: "og:url",
          content: 'https://www.sclhbc.com/#/',
        },
        {
          property: "og:image",
          content: 'https://www.sclhbc.com/favicon.ico',
        },
      ],
    };
  },
  data() {
    return {
      PageTitle: "",
      PagekeyWords: "",
      PageDescription: "",
      ruleForm: {
        name: "",//姓名
        company: "",//公司名称
        phone: "",
        city: "",
      },
      rules: {
        name: [{required: true, message: "请输入您的姓名", trigger: "blur"}],
        company: [{required: true, message: "请输入公司名称", trigger: "blur"}],
        phone: [{required: true, message: "请输入您的电话", trigger: "blur"}],
        city: [{required: true, message: "请输入所在城市", trigger: "blur"}],
      },
      business:[],//核心业务
      about:'',//关于我们
      advantage:[],//平台优势
      line_obj:{},//内容
      line_num:0,//切换
      outline:[],//线下
      online:{},//线上
      synaptic:[],//联动力
      banners: [],
      current: 0,
      bannerHeight: "41.6667vw",
      otherdata:{},
    }
  },
  mounted() {
    this.fetchSeoList()

    this.current = 0
    this.fetchIndex();
  },
  methods: {
    async fetchSeoList() {
      let {data} = await getSeoList()
      this.tit = data.data.val
      // console.log('tt',this.tit);
      this.PageTitle = this.tit.zd_name?this.tit.zd_name:'联合百川'
      this.PagekeyWords = this.tit.wz_keyword?this.tit.wz_keyword:'联合百川'
      this.PageDescription = this.tit.zd_desc?this.tit.zd_desc:'四川联合百川科技有限公司'
    },
    // 切换-线上场景 线下场景
    changeline(i){
      this.line_num = i;
    },
    // 提交
    async to_sub() {
      console.log("表单", this.ruleForm);
      if(!this.checkForm()){
        return ;
      }else{
        let {data} = await consultSub({
          name: this.ruleForm.name,
          company: this.ruleForm.company,
          phone: this.ruleForm.phone,
          city: this.ruleForm.city,
        })
        if (data.code == 1) {
          this.$message({
            message: "提交成功",
            type: "success",
          })
          this.ruleForm.name = ''
          this.ruleForm.company = ''
          this.ruleForm.phone = ''
          this.ruleForm.city = ''
        } else {
          this.$message.error(data.msg)
        }
      }
    },
    checkForm(){
      if(this.ruleForm.name == ''){
        this.$message.error('请输入您的姓名');
        return false;
      }else if(this.ruleForm.company == ''){
        this.$message.error('请输入公司名称');
        return false;
      }else if(this.ruleForm.phone == ''){
        this.$message.error('请输入您的电话');
        return false;
      }else if(this.ruleForm.city == ''){
        this.$message.error('请输入所在城市');
        return false;
      }else{
        return true;
      }
    },
    // banner-翻页
    prevBanner(){
      this.$refs.bannershow.prev();
    },
    nextBanner(){
      this.$refs.bannershow.next();
    },
    // 导航栏切换
    handleShow(index,id) {
      // console.log(index)
      this.current = index
      if (index == 1) {
        this.$router.push({
          path: "/product",
        })
        this.handelUpdate()
      }
      if (index == 2) {
        this.$router.push({
          path: "/advantage",
        })
      }
      if (index == 3) {
        this.$router.push({
          path: "/aboutus",
        })
      }
      this.handelUpdate()
      if(id){
        setTimeout(()=>{
          var el = document.getElementById(id)
          this.$nextTick(function () {
            window.scrollTo({
              behavior: "smooth",
              top: el.offsetTop + -88,
            })
          })
        },100)
      }else{
        return
      }
    },
    // 回顶部
    handelUpdate() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    },
    // 滚动到某版块
    scroll({id, offset, num}) {
      if (num == -1) {
        offset = offset || -10
        var el = document.getElementById(id)
        this.$nextTick(function () {
          window.scrollTo({
            behavior: "smooth",
            top: el.offsetTop + offset,
          })
        })
      } else {
        this.handleShow(num,id)
      }
    },
    // 其他
    async fetchOtherVal() {
      let {data} = await otherVal()
      this.otherdata = data.data;

      // console.log('other',this.otherdata);

      let productBox = document.querySelector('.productBox');
      productBox.style.backgroundImage = `url(${this.otherdata.img_one})`
      productBox.style.backgroundSize = "100% 100%"

      let onlineBox = document.querySelector('.onlineBox');
      onlineBox.style.backgroundImage = `url(${this.otherdata.img_four})`
      onlineBox.style.backgroundSize = "100% 100%"

      let advantageBox = document.querySelector('.advantageBox');
      advantageBox.style.backgroundImage = `url(${this.otherdata.img_three})`
      advantageBox.style.backgroundSize = "100% 100%"

      let aboutusBox = document.querySelector('.aboutusBox');
      aboutusBox.style.backgroundImage = `url(${this.otherdata.img_two})`
      aboutusBox.style.backgroundSize = "100% 100%"

      let consultBox = document.querySelector('.consultBox');
      // advantageBox.style.backgroundImage = `url(${this.otherdata.img_two})`
      consultBox.style.backgroundImage = `url(${this.otherdata.img_five})`
      consultBox.style.backgroundSize = "100% 100%"
    },
    // 首页
    async fetchIndex() {
      let {data} = await getIndex(
        {
          type_on:1,
        }
      )

      // console.log("index", data.data)

      this.banners = data.data.banner_list;
      this.synaptic = data.data.our_serve.serve;
      this.online = data.data.our_serve.scenes;
      this.advantage = data.data.our_serve.advantage;
      this.about = data.data.our_serve.about.content;
      this.business = data.data.our_serve.core_business;

      this.fetchIndex1();//获取线下场景
    },
    async fetchIndex1() {
      let {data} = await getIndex(
        {
          type_on:2,
        }
      )
      this.outline = data.data.our_serve.scenes;
      this.fetchOtherVal();
    },
  },
}
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  min-height: 100%;
  // padding-top: 5.2083vw;
  margin: 0px;
  background-color: #fff;
  overflow-x: hidden;

  .bannerBox {
    width: 100vw;
    
    .prev{
      opacity: 0;
    }
    .next{
      opacity: 0;
    }
  }
  .bannerBox:hover{
    .prev{
      opacity: 1;
      cursor: pointer;
    }
    .next{
      opacity: 1;
      cursor: pointer;
    }
  }
  .productBox{
    
  }

  .onlineBox{

  }

  .advantageBox{
    
  }

  .consultBox{
    ::v-deep .el-form-item{
      margin: 0;
    }
    ::v-deep .el-form--inline .el-form-item__content{
      width: 100%;
    }
    ::v-deep .el-input__inner.el-input__inner.el-input__inner{
      height: 3.125vw;
      color:#ffffff;
      font-size: 20px;
      border-radius: 0;
      background-color: rgba(255, 255, 255, 0.3);
      border: none;
      outline: none;
    }
    .el-form-item__content{
        
    }
    @media screen and (min-width: 1250px) and (max-width: 1500px){
      ::v-deep .el-input__inner.el-input__inner.el-input__inner{
        font-size: 16px;
      }
    }
    @media screen and (min-width: 750px) and (max-width: 1250px){
      ::v-deep .el-input__inner.el-input__inner.el-input__inner{
        font-size: 14px;
      }
    }
    @media screen and (max-width: 750px){
      ::v-deep .el-form-item__content{
        line-height: 20px !important;
      }
      ::v-deep .el-input__inner.el-input__inner.el-input__inner{
        font-size: 6px;
      }
    }
    ::v-deep .el-input__inner::placeholder {
      color:  #ffffff;
    }
  }

}
/* banner指示器 */
::v-deep .el-carousel__indicator--horizontal .el-carousel__button.el-carousel__button {
  width: 12px !important;
  height: 12px !important;
  border-radius: 50%;
}
</style>
