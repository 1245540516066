<template>
  <div class="main primary_color">
    <!-- nav -->
    <my-nav
      :current="current"
      @onHandleShow="handleShow"
    ></my-nav>

    <!-- 平台优势 -->
    <son-banner :title="title" :enTitle="enTitle" :current="current"></son-banner>

    <!-- 平台特点 -->
    <div id="advantage1" class="advantageBox1 column a_center w-per100 minh750">
      <div class="w-per62_5 column a_center">
        <div class="ft36 bolder mt100 wow animate__animated animate__jackInTheBox" data-wow-duration="0.6s" data-wow-iteration="1">
          联动力小程序平台特点
        </div>

        <div class="w-per100 row j_sb mt130">
          <div class="w232 br16 bg_w px25 wow animate__animated animate__flipInX"
            data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s"
            v-for="(item,index) in advantage1" :key="index">
            <img class="wh75 mt30" :src="item.thumb" >
            <div class="show_color ft20 mt15">
              {{ item.title }}
            </div>
            <div class="ft13 mt10 text_wrap lh24">{{ item.content }}</div>
            <div class="line_E85B56 mt25 mb30"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 精准用户 -->
    <div id="advantage2" class="advantageBox2 column a_center w-per100 minh842 mt23">
      <div class="w-per62_5 column a_center">
        <div class="ft36 bolder mt100 wow animate__animated animate__jackInTheBox" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
          把您的广告展现给精准用户
        </div>

        <div class="w-per100">
          <div class="mt202">
            <img class="w-per100 h300" src="https://www.sclhbc.com/common/panel_jingzhunkehu.png" alt="">
          </div>
          <div class="w-per100 h370 row j_se mt-370">
            <div class="w-per33 h-per100 column a_center" v-for="(item,index) in advantage2" :key="index">
              <img class="wh140 wow animate__animated animate__zoomIn" 
                data-wow-duration="0.6s" data-wow-iteration="1" :data-wow-delay="(index+1)*0.2+'s'"
                :src="item.thumb" alt="">
              <div class="mt40 ft24 show_color">
                {{ item.title }}
              </div>
              <div class="mt20 w-per72 ft18 text_center text_wrap lh36">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 粉丝百万 -->
    <div id="advantage3" class="advantageBox3 column a_center w-per100 minh1100">
      <div class="w-per62_5 column a_center">
        <div class="ft36 bolder mt100 wow animate__animated animate__jackInTheBox" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
          平台资源联动，覆盖粉丝100万+
        </div>

        <div class="w-per100 mt90 mb130 row wrap j_center">
          <div class="w-per49 mt22 br16 bg_w px30 row j_sb wow animate__animated animate__bounceIn"
            data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s"
            :class="index%2==1?'ml20':''"
            v-for="(item,index) in advantage3" :key="index">
             <div class="mt25 mb50 w-per70">
              <span class="ft24 bolder show_color">
                {{ item.title }}
              </span>
              <div class="ft18 mt20 mb20 lh36">
                {{ item.content }}
              </div>
             </div>
             <div class="mt60 mb50">
              <img class="wh100" :src="item.thumb" alt="">
             </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 战略 推广 -->
    <div class="advantageBox column a_center w-per100 minh900">
      <div class="w-per62_5 mt125 mb175 column a_center">

        <div class="w-per100 bg_w br16 shadow_3 row j_sb wow animate__animated animate__fadeIn" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
          <div class="w-per41 ml80 mt70 mb40">
            <div class="show_color ft40 lh56 text_wrap">{{advantage_obj.title?advantage_obj.title.replaceAll(` `,`\n`):''}}</div>
            <div class="w-per100 mt50">
              <div class="w-per100 row mt40" v-if="advantage_obj.text1">
                <div class="wh14 br-per100 bg_E85B56 mt5"></div>
                <div class="ml15 ft20 flex1 lh36">
                  {{ advantage_obj.text1 }}
                </div>
              </div>
              <div class="w-per100 row mt40" v-if="advantage_obj.text2">
                <div class="wh14 br-per100 bg_E85B56 mt5"></div>
                <div class="ml15 ft20 flex1 lh36">
                  {{ advantage_obj.text2 }}
                </div>
              </div>
            </div>
          </div>
          <div class="w-per43 h-per82 mr55 my45">
            <img class="w-per100 h-per100" :src="advantage_obj.thumb" alt="">
          </div>
        </div>

        <div class="w-per100 h60 mt45 bg_w br10 shadow_2 row pointer">
          <div class="w-per50 h-per100 row flex_center nowrap"
            :class="index==advantage_num?'bg_E85B56 br10':''"
            v-for="(item,index) in advantage" :key="index" @click="changeAdvantage(item,index)">
            <div class="wh40 row flex_center nowrap">
              <img class="wh40" :src="index==advantage_num ? item.img2 : item.img1" alt="">
            </div>
            <div class="ft20 ml30" :class="index==advantage_num?'white_color':'show_color'">
              {{ item.title.replaceAll(' ','') }}
            </div>
          </div>
        </div>
        
      </div>
    </div>

    <!-- footer -->
    <my-footer :current="current" @onScrollTo="scroll"></my-footer>
  </div>
</template>

<script>
import { getSeoList, getAdvantage, otherVal } from "@/api/app"
export default {
  metaInfo() {
    return {
      title: '平台优势',
      meta: [
        {
          name: "keywords",
          content: this.PagekeyWords,
        },
        {
          name: "description",
          content: this.PageDescription,
        },
        {
          property: "og:type",
          content: 'website',
        },
        {
          property: "og:title",
          content: this.PageTitle,
        },
        {
          property: "og:description",
          content: this.PageDescription,
        },
        {
          property: "og:site_name",
          content: this.PageTitle,
        },
        {
          property: "og:url",
          content: 'https://www.sclhbc.com/#/advantage',
        },
        {
          property: "og:image",
          content: 'https://www.sclhbc.com/favicon.ico',
        },
      ],
    };
  },
  data() {
    return {
      PageTitle: "",
      PagekeyWords: "",
      PageDescription: "",
      advantage_obj:{},
      advantage_num:0,//翻页
      advantage:[],//战略合作 AI推广
      advantage3:[],//粉丝百万
      advantage2:[],//精准用户
      advantage1:[],//平台特点
      title:'平台优势',
      enTitle:'PLATFORM ADVANTAGE',
      current: 2,
    }
  },
  mounted() {
    this.fetchSeoList()

    this.fetchAdvantage();
  },
  methods: {
    async fetchSeoList() {
      let {data} = await getSeoList()
      this.tit = data.data.val
      // console.log('tt',this.tit);
      this.PageTitle = this.tit.zd_name?this.tit.zd_name:'联合百川'
      this.PagekeyWords = this.tit.wz_keyword?this.tit.wz_keyword:'联合百川'
      this.PageDescription = this.tit.zd_desc?this.tit.zd_desc:'四川联合百川科技有限公司'
    },
    // 切换
    changeAdvantage(it,i){
      this.advantage_num = i;
      this.advantage_obj = it;
    },
    handleShow(index,id) {
      // console.log(id,index)
      this.current = index
      if (index == 0) {
        this.$router.push({
          path: "/",
        })
      }
      if (index == 1) {
        this.$router.push({
          path: "/product",
        })
      }
      if (index == 3) {
        this.$router.push({
          path: "/aboutus",
        })
      }
      this.handelUpdate()
      if(id){
        setTimeout(()=>{
          var el = document.getElementById(id)
          this.$nextTick(function () {
            window.scrollTo({
              behavior: "smooth",
              top: el.offsetTop + -88,
            })
          })
        },100)
      }else{
        return
      }
    },
    handelUpdate() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    },
    scroll({id, offset, num}) {
      // console.log("111")
      if (num == -1) {
        offset = offset || -10
        var el = document.getElementById(id)
        this.$nextTick(function () {
          window.scrollTo({
            behavior: "smooth",
            top: el.offsetTop + offset,
          })
        })
      } else {
        this.handleShow(num,id)
      }
    },
    async fetchAdvantage(){
      let {data} = await getAdvantage()
      // console.log('getAdvantage',data.data);
      this.advantage1 = data.data.list;
      this.advantage2 = data.data.list_two;
      this.advantage3 = data.data.list_three;
      this.advantage = data.data.list_four;
      this.advantage_obj = this.advantage[this.advantage_num]?this.advantage[this.advantage_num]:{};

      this.fetchOtherVal();
    },
    async fetchOtherVal() {
      let {data} = await otherVal()
      this.otherdata = data.data;

      // console.log('other',this.otherdata);
      let advantageBox1 = document.querySelector('.advantageBox1');
      advantageBox1.style.backgroundImage = `url(${this.otherdata.img_twenty_three})`
      advantageBox1.style.backgroundSize = "100% 100%"

      let advantageBox2 = document.querySelector('.advantageBox2');
      advantageBox2.style.backgroundImage = `url(${this.otherdata.img_twenty_two})`
      advantageBox2.style.backgroundSize = "100% 100%"

      let advantageBox3 = document.querySelector('.advantageBox3');
      advantageBox3.style.backgroundImage = `url(${this.otherdata.img_twenty})`
      advantageBox3.style.backgroundSize = "100% 100%"
      
      let advantageBox = document.querySelector('.advantageBox');
      advantageBox.style.backgroundImage = `url(${this.otherdata.img_twenty_one})`
      advantageBox.style.backgroundSize = "100% 100%"
    },
  },
}
</script>

<style scoped lang="scss">
.main{
  width: 100%;
  min-height: 100%;
  // padding-top: 5.2083vw;
  margin: 0px;
  background-color: #fff;
  position: relative;
  overflow-x: hidden;
}
</style>
