<template>
  <div class="navbar row flex_center bg_390B40">
    <div class="w-per62_5 row j_sb a_center">
      <img
      class="logoimg1"
      :src="logo"
      alt=""
      @click="handleShow(0)"
    />

    <ul class="row">
      <li
        class="row flex_center ft18 white_color relative px30 pointer"
        :class="current==index?'bg_E85B56':''"
        v-for="(item, index) in navList"
        :key="index"
        @click="handleShow(index)"
      >
        <span>{{ item.name }}</span>
      </li>
    </ul>
    </div>
  </div>
</template>
<script>
import {otherVal} from "@/api/app"
export default {
  data() {
    return {
      navList: [
        {
          name:'首页',
        },
        {
          name:'产品展示',
        },
        {
          name:'平台优势',
        },
        {
          name:'关于我们',
        },
      ],
      logo: "",
    }
  },
  props: {
    current: Number,
  },
  mounted() {
    this.fetchOtherVal()
  },
  methods: {
    handleShow(index) {
      this.$emit("onHandleShow", index)
    },
    async fetchOtherVal() {
      let {data} = await otherVal()
      this.logo = data.data.logo
    },
  },
}
</script>
<style lang="scss">
.navbar {
  width: 100vw;
  // height: 5.2083vw;
  // height: 4.5833vw;
  height: 3.90625vw;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 666;
  .logoimg1 {
    /* width: 13.4375vw;
    height: 4.1667vw; */
    // width: 9.8958vw;
    // height: 3.2292vw;
    width: 9.1146vw;
    height: 2.8125vw;
  }
  >div{
    > ul {
      // height: 5.2083vw;
      // height: 4.5833vw;
      height: 3.90625vw;
      > li {
        height: 100%;
        // transition: all 0.3s linear;
      }
      > li:hover{
        background: linear-gradient(90deg, #E85B56 0%, #390B40 100%);
      }
    }
  }
}
</style>
