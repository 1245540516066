<template>
  <div class="w-per100 column flex_center footerBox bg_1B2032 white_color ft16">
    <div class="w-per62_5 footer row j_sb">
      <div class="flex1 row j_sb">
        <ul
          class="column"
          v-for="(item, index) in list"
          :key="item.id"
        >
          <li class="ft16 pointer mt20" @click="scrollTo(index,item.catname)">{{ item.catname }}</li>
          <li class="ft15 pointer mt20" v-for="(ite,ind) in item.child" :key="ind" @click="scrollTo(index,ite.catname,ind)">{{ ite.catname }}</li>
        </ul>
      </div>
      <div class="ml200 my18">
        <img class="w150 h174" :src="logo" alt="" />
      </div>
    </div>
    <div class="ft13 mt20 input_color row flex_center">
      <a href="https://beian.miit.gov.cn" target="_blank">{{ icp }}</a>
    </div>
  </div>
</template>
<script>
import {bottomNav, otherVal} from "@/api/app"

export default {
  data() {
    return {
      list: [],
      logo: "",
      icp: "",
    }
  },
  props: {
    current: Number,
  },
  mounted() {
    this.list = [
        {
          catname:'首页',
          child:[
          {
            catname:'产品展示'
          },
          {
            catname:'平台优势'
          },
          {
            catname:'关于我们'
          },
          ]
        },
        {
          catname:'产品展示',
          child:[
            {
              catname:'助推产品曝光'
            },
            {
              catname:'联动力推广平台'
            },
            {
              catname:'全系列多场景用户流量'
            },
          ]
        },
        {
          catname:'平台优势',
          child:[
            {
              catname:'联动力小程序平台特点'
            },
            {
              catname:'把您的广告展现给精准用户'
            },
            {
              catname:'平台资源联动'
            },
          ]
        },
        {
          catname:'关于我们',
          child:[
            {
              catname:'公司介绍'
            },
            {
              catname:'核心业务'
            },
            {
              catname:'联系我们'
            },
          ]
        },
      ]
    this.fetchOtherVal()
  },
  methods: {
    scrollTo(num,text,li) {
      console.log(num,text,li)
      let data = {}
      if (num == this.current) {// 本页面滚动
        if (text == "首页" && li==undefined) {
          data.num = 0
        } else if (text == "产品展示" && li==undefined) {
          data.num = 1
        } else if (text == "平台优势" && li==undefined) {
          data.num = 2
        } else if (text == "关于我们" && li==undefined) {
          data.num = 3
        } else {
          data.num = -1
          // num=0
          if (text == "产品展示") {
            data.id = "product"
          }
          if (text == "平台优势") {
            data.id = "advantage"
          }
          if (text == "关于我们") {
            data.id = "aboutus"
          }
          // num=1
          if (text == "助推产品曝光") {
            data.id = 'product1'
          }
          if (text == "联动力推广平台") {
            data.id = 'product2'
          }
          if (text == "全系列多场景用户流量") {
            data.id = 'product3'
          }
          // num=2
          if (text == "联动力小程序平台特点") {
            data.id = "advantage1"
          }
          if (text == "把您的广告展现给精准用户") {
            data.id = "advantage2"
          }
          if (text == "平台资源联动") {
            data.id = "advantage3"
          }
          // num=3
          if (text == "公司介绍") {
            data.id = "aboutus1"
          }
          if (text == "核心业务") {
            data.id = "aboutus2"
          }
          if (text == "联系我们") {
            data.id = "aboutus3"
          }
        }
        data.offset = -88
      } else {
        // 跳转路由
        data.num = num;
        data.offset = -88
        // num=0
        switch (num){
          case 0:
            if (text == "产品展示") {
              data.id = "product"
            }
            if (text == "平台优势") {
              data.id = "advantage"
            }
            if (text == "关于我们") {
              data.id = "aboutus"
            }
            break;
          case 1:
            if (text == "助推产品曝光") {
              data.id = 'product1'
            }
            if (text == "联动力推广平台") {
              data.id = 'product2'
            }
            if (text == "全系列多场景用户流量") {
              data.id = 'product3'
            }
            break;
          case 2:
            if (text == "联动力小程序平台特点") {
              data.id = "advantage1"
            }
            if (text == "把您的广告展现给精准用户") {
              data.id = "advantage2"
            }
            if (text == "平台资源联动") {
              data.id = "advantage3"
            }
            break;
          case 3:
            if (text == "公司介绍") {
              data.id = "aboutus1"
            }
            if (text == "核心业务") {
              data.id = "aboutus2"
            }
            if (text == "联系我们") {
              data.id = "aboutus3"
            }
            break;
          default:
            break;
        }
      }
      console.log("data", data)
      this.$emit("onScrollTo", data)
    },
    async fetchOtherVal() {
      let {data} = await otherVal()
      this.logo = data.data.bottom_logo
      this.icp = data.data.icp
      // this.icp = '蜀ICP备2023016377号'
    },
  },
}
</script>
<style lang="scss">
.footerBox {
  // height: 280px;
  padding: 10px 0px 20px;
  .footer {
    border-bottom: 1px solid rgba(244,244,244,0.5);
  }
  a{
      color: #999999;
      text-decoration: none;
    }
    a:link{
      color:#999999;
    }
    a:visited{
      color: #999999;
    }
    a:hover{
      color: #999999;
    }
    a:active{
      color: #999999;
    }
}
</style>
